import React, { useState, useEffect, useRef, Fragment } from "react";
import { useStore } from "react-hookstore";
import { Delete, Edit } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import Style from "./UsersPage.module.css";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import ListView from "../../components/TrackPageComponents/ListView";
import UserModal from "../../components/users-page/UserModal";
import LoadingCircle from "../../components/loading/LoadingCircle";
import SearchableTabList from "../../components/searchable-tab-list/SearchableTabList";
import DisplayBanner from "../../components/display-banner/DisplayBanner";
import { genericSort, naturalSort } from "../../common/Utilities";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import { userProfileStore } from "../../state-managements/stores/user-profile-store";
import { ReactComponent as UserIcon } from "../../assets/icons/users.svg";
import xemelgoStyle from "../../styles/variable";
import ScreenFrame from "../../components/ScreenFrame/ScreenFrame";
import { isEqual } from "lodash";
import useMixpanelContext from "../../context/mixpanel-context";
import { USERS_APP_EVENT, USERS_APP_EVENT_STEPS } from "../../constants/mixpanel-constant/usersApp";

const title = "Users";
const mainColor = xemelgoStyle.theme.XEMELGO_BLUE;
const secondaryColor = xemelgoStyle.theme.XEMELGO_LIGHTBLUE;
const RESEND_PASSWORD_STATUS = "FORCE_CHANGE_PASSWORD";
const defaultSortMethod = "name";
const APP_ID = "usersPage";
const ROLE_ENUM_ADMIN = "ADMIN";
const ROLE_ENUM_USER = "USER";
const ROLE_ENUM_VENDOR_CLIENT_ADMIN = "VENDOR_CLIENT_ADMIN";
const ROLE_ENUM_VENDOR_CLIENT_USER = "VENDOR_CLIENT_USER";
const ROLE_ENUM_TRACKING_ONLY = "TRACKING_ONLY";

const APPSYNC_EMAIL_ERROR_TYPES = ["EmailRequiredError", "UnexpectedEmailError", "UsernameExistsException"];

const APPSYNC_ROLE_ERROR_TYPES = ["SameRoleError", "AliasExistsException"];

const APPSYNC_ALIAS_ERROR_TYPES = ["DuplicateAliasError", "SameAliasError"];

const APPSYNC_TRACKER_SERIAL_ERROR_TYPES = [
  "DuplicateTrackerSerialError",
  "SameTrackerSerialError",
  "TrackerSerialRequiredError"
];

/*
  Attributes for the user create/edit form

  hiddenForVendorClientUser - if true, this field will not be availble for vendor client user create/edit
  hiddenForTenantUser - if true, this field will not be available for tenant user create/edit
  options.roles - array of user roles that will be able to see those options
*/
const attributes = {
  firstName: {
    label: "First Name",
    index: 1,
    editable: false,
    required: true,
    type: "input"
  },
  lastName: {
    label: "Last Name",
    index: 2,
    editable: false,
    required: true,
    type: "input"
  },
  email: {
    label: "E-mail",
    index: 3,
    editable: true,
    required: false,
    type: "email"
  },
  phone: {
    label: "Phone",
    index: 4,
    editable: false,
    type: "phone"
  },
  alias: {
    autoGenerate: true,
    editable: true,
    index: 6,
    label: "Alias",
    type: "input"
  },
  role: {
    index: 7,
    label: "Role",
    editable: true,
    maximumChecked: 1,
    minimumChecked: 1,
    options: [
      {
        id: "ADMIN",
        label: "Admin",
        hiddenForVendorClientUser: true,
        roles: ["admin"]
      },
      {
        id: "USER",
        label: "User",
        hiddenForVendorClientUser: true,
        roles: ["admin"]
      },
      {
        id: "VENDOR_CLIENT_ADMIN",
        label: "Admin",
        hiddenForTenantUser: true,
        roles: ["admin"]
      },
      {
        id: "VENDOR_CLIENT_USER",
        label: "User",
        hiddenForTenantUser: true,
        roles: ["admin", "vendorClientAdmin"]
      },
      {
        id: "TRACKING_ONLY",
        label: "Tracking Only",
        roles: ["admin", "vendorClientAdmin"],
        requiresUserTrackingConfig: true
      }
    ],
    type: "checkboxGroup"
  }
};

/*
 The mapping from role enum to display drops the 'vendor client'
 terminology since the create/edit table only shows either the 
 ADMIN/USER for a tenant user or VENDOR_CLIENT_ADMIN/VENDOR_CLIENT_USER
 for a vendor client user.  They never appear on the form at the 
 same time. For example, if you are creating a user from the 
 customer tab, it will create either a VENDOR_CLIENT_ADMIN or 
 VENDOR_CLIENT_USER user depending on which is selected.
*/
const ROLE_ENUM_TO_DISPLAY = {
  [ROLE_ENUM_ADMIN]: "admin",
  [ROLE_ENUM_USER]: "user",
  [ROLE_ENUM_VENDOR_CLIENT_ADMIN]: "admin",
  [ROLE_ENUM_VENDOR_CLIENT_USER]: "user",
  [ROLE_ENUM_TRACKING_ONLY]: "tracking only"
};

const UsersPageFeature = ({ history }) => {
  const client = useXemelgoClient();
  const xemelgoClientAppSync = useXemelgoAppsyncClient();
  const [UserClient] = useState(xemelgoClientAppSync.getUserClient());
  const { sendMixPanelEvent } = useMixpanelContext();

  const configProvider = useAppConfigProvider(APP_ID);
  const usersPageConfig = configProvider.config;

  const {
    customerName: tenantName = "",
    hasVendorClients = false,
    hasUserTracking = false,
    additionalAttributes = {},
    // roles is an array of roles which define which type of logged in user can create users
    createButton = { roles: ["admin"] },
    /*
    For edit/delete button roles.  The key is the role of the logged in user. 
    The 'userRoles' is an array of role enum's which dictate which type of 
    users the logged in user will be able to edit/delete.
    */
    editButton = {
      roles: {
        admin: {
          userRoles: [
            ROLE_ENUM_ADMIN,
            ROLE_ENUM_USER,
            ROLE_ENUM_VENDOR_CLIENT_ADMIN,
            ROLE_ENUM_VENDOR_CLIENT_USER,
            ROLE_ENUM_TRACKING_ONLY
          ]
        }
      }
    },
    deleteButton = {
      roles: {
        admin: {
          userRoles: [
            ROLE_ENUM_ADMIN,
            ROLE_ENUM_USER,
            ROLE_ENUM_VENDOR_CLIENT_ADMIN,
            ROLE_ENUM_VENDOR_CLIENT_USER,
            ROLE_ENUM_TRACKING_ONLY
          ]
        }
      }
    }
  } = usersPageConfig;
  const combinedAttributes = { ...attributes, ...additionalAttributes };

  /*
    User page headers

    roles - array of user roles which define which users can see the header
  */
  const genericListHeaders = [
    {
      id: "displayName",
      label: "NAME",
      default: defaultSortMethod === "displayName",
      roles: ["admin", "vendorClientAdmin"]
    },
    {
      id: "email",
      label: "EMAIL ADDRESS",
      roles: ["admin", "vendorClientAdmin"]
    },
    {
      id: "displayRole",
      label: "ROLE",
      roles: ["admin", "vendorClientAdmin"]
    },
    {
      id: "alias",
      label: "ALIAS",
      roles: ["admin", "vendorClientAdmin"]
    }
  ];

  if (hasUserTracking) {
    const tagHeader = {
      id: "trackerSerial",
      label: "TAG ID",
      roles: ["admin", "vendorClientAdmin"]
    };
    genericListHeaders.splice(3, 0, tagHeader);
  }

  genericListHeaders.forEach((header) => {
    header.renderComponent = (value, id) => {
      return renderCell(value, id, header.id);
    };
  });

  const [userAttributes] = useState(combinedAttributes);
  const [loading, setLoading] = useState(true);
  const [currentAction, setCurrentAction] = useState({});
  const [currentPartner, setCurrentPartner] = useState({});
  const [partnerTabs, setPartnerTabs] = useState({});
  const [usersList, setUsersList] = useState({});
  const [formData, setFormData] = useState({});
  const [showBanner, setShowBanner] = useState(false);
  const [bannerError, setBannerError] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [listHeaders, setListHeaders] = useState(genericListHeaders);
  const [userProfile] = useStore(userProfileStore);

  const usersRef = useRef(usersList);
  usersRef.current = usersList;

  useEffect(() => {
    sendMixPanelEvent(USERS_APP_EVENT, USERS_APP_EVENT_STEPS.ENTRY);
    onLoad();
  }, []);

  useEffect(() => {
    const { action = "", user = {}, isVendorClientUser } = currentAction;
    const formDataToUpdate = {};
    if (action.length) {
      for (const key of Object.keys(userAttributes)) {
        const { type, options, hiddenForVendorClientUser } = userAttributes[key];
        if (isVendorClientUser && hiddenForVendorClientUser) {
          continue;
        }
        let value = user[key] || "";
        if (type === "checkboxGroup") {
          value = {};
          options.forEach((option) => {
            const {
              hiddenForTenantUser: optionHiddenForTenantUser,
              hiddenForVendorClientUser: optionHiddenForVendorClientUser,
              requiresUserTrackingConfig,
              roles
            } = option;

            if (
              (!requiresUserTrackingConfig || (requiresUserTrackingConfig && hasUserTracking)) &&
              roles.includes(userProfile.getRole()) &&
              ((isVendorClientUser && !optionHiddenForVendorClientUser) ||
                (!isVendorClientUser && !optionHiddenForTenantUser))
            ) {
              value[option.id] = user[key] === option.id || false;
            }
          });
        }
        formDataToUpdate[key] = {
          ...userAttributes[key],
          disabled: action === "edit" && !userAttributes[key].editable,
          value
        };
      }
    }
    setFormData(formDataToUpdate);
  }, [currentAction, userAttributes]);

  useEffect(() => {
    let listHeadersToSet = [...genericListHeaders];

    if (hasVendorClients && currentPartner.identifier !== tenantName) {
      listHeadersToSet = listHeadersToSet.filter((header) => {
        return !header.hiddenForVendorClients;
      });
    }

    listHeadersToSet = listHeadersToSet.filter((header) => {
      return header.roles.includes(userProfile.getRole());
    });

    setListHeaders(listHeadersToSet);
  }, [currentPartner, hasVendorClients, tenantName]);

  const onLoad = async () => {
    // Clear states
    setLoading(true);

    await listUsers();

    let partners;
    if (hasVendorClients) {
      partners = await listVendorClients();
    }

    // Only top level tenant users have access to the tenant user tab
    if (!userProfile.getPartnerId() && !Object.keys(currentPartner).length) {
      setCurrentPartner({ id: "tenant", identifier: tenantName });
    }

    // Vendor client users need to be dropped into the first partner
    // they have access to since there is no top level tenant users tab
    if (userProfile.getPartnerId() && !Object.keys(currentPartner).length && partners) {
      setCurrentPartner(partners[0]);
    }

    setLoading(false);
  };

  const getCustomProperties = (props, valueField = null) => {
    return Object.keys(props ?? {}).reduce((accum, key) => {
      if (!key.endsWith("_ts")) {
        return accum;
      }

      accum[key] = valueField ? props[key][valueField] : props[key];
      return accum;
    }, {});
  };

  const listUsers = async () => {
    const queryUsersResponse = await UserClient.queryUsers();

    const userToCustomerMap = queryUsersResponse.queryUsers.users.reduce((accum, user) => {
      // Only show person nodes who have a cognito id if it is not a vendor client solution
      if (!hasVendorClients && user.role === "TRACKING_ONLY") {
        return accum;
      }

      user.displayRole = ROLE_ENUM_TO_DISPLAY[user.role];
      user.displayName = `${user.firstName} ${user.lastName}`;

      const customProperties = getCustomProperties(JSON.parse(user.customProperties));
      delete user.customProperties;
      Object.keys(customProperties).forEach((key) => {
        const value = customProperties[key];
        if (value === undefined || value === null) {
          return;
        }
        user[key] = customProperties[key];
      });

      const customerIdentifier = user.customer?.identifier || tenantName;

      accum[customerIdentifier] = [user, ...(accum[customerIdentifier] || [])];

      return accum;
    }, {});

    Object.keys(userToCustomerMap).forEach((group) => {
      userToCustomerMap[group] = genericSort(userToCustomerMap[group], "displayName");
    });

    setUsersList(userToCustomerMap);
  };

  const listVendorClients = async () => {
    const PartnerClient = client.getPartnerClient();
    let partnersList = await PartnerClient.listPartners();

    // Only level tenant users get access to the tenant users tab
    if (!userProfile.getPartnerId()) {
      partnersList.push({ id: "tenant", identifier: tenantName });
    }

    partnersList = naturalSort(partnersList, "identifier");

    // Set the parent tenant at the top of the user groups list
    partnersList.sort((x, y) => {
      return x.identifier === tenantName ? -1 : y.identifier === tenantName ? 1 : 0;
    });

    const partnerTabsToUpdate = {
      title: "User Groups",
      tabs: partnersList
    };
    setPartnerTabs(partnerTabsToUpdate);
    return partnersList;
  };

  const getRolesFromFormData = (formDataRole) => {
    return Object.keys(formDataRole.value).reduce((accum, key) => {
      if (formDataRole.value[key]) {
        accum.push(key);
      }
      return accum;
    }, []);
  };

  const isTrackingOnlyRole = (roleEnum) => {
    return roleEnum === "TRACKING_ONLY";
  };

  const isCreateAuthorized = (roles) => {
    return roles.includes(userProfile.getRole());
  };

  const isAuthorized = (roles, userRole) => {
    return (
      Object.keys(roles).includes(userProfile.getRole()) &&
      (!userRole || roles[userProfile.getRole()].userRoles.includes(userRole))
    );
  };

  const validateEmail = (formData, isTrackingOnly, roleEnum) => {
    if (formData.email) {
      if (isTrackingOnly && formData.email?.value) {
        formData.email.error = true;
        formData.email.errorMessage = `Can not specify an email with the ${ROLE_ENUM_TO_DISPLAY[roleEnum]} role`;
      } else if (!isTrackingOnly && !formData.email?.value) {
        formData.email.error = true;
        formData.email.errorMessage = `An email is required with the ${ROLE_ENUM_TO_DISPLAY[roleEnum]} role`;
      } else if (formData) {
        formData.email.error = false;
        formData.email.errorMessage = ``;
      }
    }
  };

  const validateEmailEdit = (formData, user, roleEnum, isTrackingOnly) => {
    if (!isTrackingOnly && formData.email?.value !== user.email && user.role === roleEnum) {
      formData.email.error = true;
      formData.email.errorMessage = `Email can only be modified when the user's role is changed`;
    }
  };

  const validateRole = (formData) => {
    let roleEnum;
    if (formData.role) {
      const { minimumChecked, maximumChecked } = formData.role;
      const roleEnums = getRolesFromFormData(formData.role);
      if (roleEnums.length < minimumChecked) {
        formData.role.error = true;
        formData.role.errorMessage = `Please choose a minimum of ${minimumChecked} options(s)`;
      } else if (roleEnums.length > maximumChecked) {
        formData.role.error = true;
        formData.role.errorMessage = `Please choose a maximum of ${maximumChecked} option(s)`;
      } else if (!roleEnums.length) {
        formData.role.error = true;
        formData.role.errorMessage = `A role must be selected`;
      }
      roleEnum = roleEnums[0];
    }
    return roleEnum;
  };

  const validatePhone = (formData, isTrackingOnly, roleEnum) => {
    if (formData.phone) {
      if (isTrackingOnly && formData.phone?.value) {
        formData.phone.error = true;
        formData.phone.errorMessage = `Can not specify a phone number with the ${ROLE_ENUM_TO_DISPLAY[roleEnum]} role`;
      } else if (formData) {
        formData.phone.error = false;
        formData.phone.errorMessage = ``;
      }
    }
  };

  const createUser = async () => {
    let needToReturn;

    setShowBanner(false);
    setBannerError(false);
    setBannerMessage("");

    const formDataToValidate = { ...formData };
    const { isVendorClientUser } = currentAction;

    Object.keys(formDataToValidate).forEach((key) => {
      if (typeof formDataToValidate[key].value === "string") {
        formDataToValidate[key].value = formDataToValidate[key].value.trim();
      }
      const { required, value, label } = formDataToValidate[key];
      if (required && !value.length) {
        formDataToValidate[key].error = true;
        formDataToValidate[key].errorMessage = `${label} is a required field`;
      }
    });

    const roleEnum = validateRole(formDataToValidate);
    const isTrackingOnly = isTrackingOnlyRole(roleEnum);

    validateEmail(formDataToValidate, isTrackingOnly, roleEnum);
    validatePhone(formDataToValidate, isTrackingOnly, roleEnum);

    Object.keys(formDataToValidate).forEach((key) => {
      const { error } = formDataToValidate[key];
      if (error) {
        needToReturn = true;
      }
    });

    if (needToReturn) {
      setFormData(formDataToValidate);
      return;
    }

    setLoading(true);

    try {
      const customerIdentifier = isVendorClientUser ? currentPartner.identifier : null;
      const createUserParams = {
        firstName: formData.firstName.value,
        lastName: formData.lastName.value,
        alias: formData.alias.value,
        email: formData.email.value,
        trackerSerial: formData.trackerSerial?.value,
        role: roleEnum,
        customerIdentifier,
        customProperties: getCustomProperties(formData, "value")
      };
      await UserClient.createUser(createUserParams);
      sendMixPanelEvent(USERS_APP_EVENT, USERS_APP_EVENT_STEPS.CREATE_USER_SUCCESS, {
        payload: JSON.stringify(createUserParams)
      });
    } catch (e) {
      sendMixPanelEvent(USERS_APP_EVENT, USERS_APP_EVENT_STEPS.CREATE_USER_FAILED, {
        errorMessage: e.message
      });
      let needToReturn = false;

      if (APPSYNC_EMAIL_ERROR_TYPES.includes(e.errorType)) {
        formDataToValidate.email.error = true;
        formDataToValidate.email.errorMessage = e.message;
      }

      if (APPSYNC_ROLE_ERROR_TYPES.includes(e.errorType)) {
        formDataToValidate.role.error = true;
        formDataToValidate.role.errorMessage = e.message;
      }

      if (APPSYNC_ALIAS_ERROR_TYPES.includes(e.errorType)) {
        formDataToValidate.alias.error = true;
        formDataToValidate.alias.errorMessage = e.message;
      }

      if (APPSYNC_TRACKER_SERIAL_ERROR_TYPES.includes(e.errorType)) {
        formDataToValidate.trackerSerial.error = true;
        formDataToValidate.trackerSerial.errorMessage = e.message;
      }

      Object.keys(formDataToValidate).forEach((key) => {
        const { error } = formDataToValidate[key];
        if (error) {
          needToReturn = true;
        }
      });

      if (needToReturn) {
        setLoading(false);
        setFormData(formDataToValidate);
        return;
      }

      const errorMessage = isTrackingOnly
        ? `Could not add ${formData.firstName.value} ${formData.lastName.value}`
        : `Could not invite ${formData.email?.value}`;

      onFail(`${errorMessage}, please try again`);
      return;
    }

    setCurrentAction({});
    setFormData({});
    setLoading(false);
    await onLoad();
    setShowBanner(true);
    const message = isTrackingOnly
      ? `User ${formData.firstName.value} ${formData.lastName.value} has been created`
      : `Your invitation has been sent to ${formData.email?.value}`;
    setBannerMessage(message);
  };

  const editUser = async () => {
    const { id, email, alias, trackerSerial, role, displayName, isVendorClientUser, ...otherProps } =
      currentAction.user;

    let nothingToUpdate = true;
    let needToReturn;

    setShowBanner(false);
    setBannerError(false);
    setBannerMessage("");

    const formDataToValidate = { ...formData };

    const roleEnum = validateRole(formDataToValidate);

    const isTrackingOnly = isTrackingOnlyRole(roleEnum);

    const userTrackerSerial = trackerSerial ?? "";
    const customProperties = getCustomProperties(otherProps);
    const formDataCustomProperties = getCustomProperties(formData, "value");

    validateEmail(formDataToValidate, isTrackingOnly, roleEnum);
    validateEmailEdit(formDataToValidate, currentAction.user, roleEnum, isTrackingOnly);

    Object.keys(formDataToValidate).forEach((key) => {
      const { error } = formDataToValidate[key];
      if (error) {
        needToReturn = true;
      }
    });

    if (needToReturn) {
      setFormData(formDataToValidate);
      return;
    }

    setLoading(true);
    Object.keys(formData).forEach((key) => {
      if (typeof formData[key].value === "string") {
        formData[key].value.trim();
      }
    });

    if (roleEnum != role) {
      nothingToUpdate = false;
      try {
        const customerIdentifier = isVendorClientUser ? currentPartner.identifier : null;
        const updateUserRoleParams = {
          id,
          email: formData.email.value,
          role: roleEnum,
          customerIdentifier
        };
        await UserClient.updateUserRole(updateUserRoleParams);
      } catch (e) {
        console.log(e);
        let needToReturn = false;

        if (APPSYNC_EMAIL_ERROR_TYPES.includes(e.errorType)) {
          formDataToValidate.email.error = true;
          formDataToValidate.email.errorMessage = e.message;
        }

        if (APPSYNC_ROLE_ERROR_TYPES.includes(e.errorType)) {
          formDataToValidate.role.error = true;
          formDataToValidate.role.errorMessage = e.message;
        }

        Object.keys(formDataToValidate).forEach((key) => {
          const { error } = formDataToValidate[key];
          if (error) {
            needToReturn = true;
          }
        });

        if (needToReturn) {
          setLoading(false);
          setFormData(formDataToValidate);
          return;
        }

        onFail(`Could not update ${displayName}, please try again`);
        return;
      }
    }

    if (formData.alias.value !== alias) {
      nothingToUpdate = false;
      try {
        await UserClient.updateUserAlias(id, formData.alias.value);
      } catch (e) {
        console.log(e);
        let needToReturn = false;

        if (APPSYNC_ALIAS_ERROR_TYPES.includes(e.errorType)) {
          formDataToValidate.alias.error = true;
          formDataToValidate.alias.errorMessage = e.message;
        }

        Object.keys(formDataToValidate).forEach((key) => {
          const { error } = formDataToValidate[key];
          if (error) {
            needToReturn = true;
          }
        });

        if (needToReturn) {
          setLoading(false);
          setFormData(formDataToValidate);
          return;
        }

        onFail(`Could not update ${displayName}, please try again`);
        return;
      }
    }

    if (!isEqual(formDataCustomProperties, customProperties)) {
      nothingToUpdate = false;
      try {
        await UserClient.updateUserCustomProperties(id, formDataCustomProperties);
      } catch (e) {
        onFail(`Could not update user properties, please try again`);
        return;
      }
    }

    if (hasUserTracking && formData.trackerSerial && formData.trackerSerial.value !== userTrackerSerial) {
      nothingToUpdate = false;
      try {
        await UserClient.updateUserTrackerSerial(id, formData.trackerSerial.value);
      } catch (e) {
        console.log(e);
        let needToReturn = false;

        if (APPSYNC_TRACKER_SERIAL_ERROR_TYPES.includes(e.errorType)) {
          formDataToValidate.trackerSerial.error = true;
          formDataToValidate.trackerSerial.errorMessage = e.message;
        }

        Object.keys(formDataToValidate).forEach((key) => {
          const { error } = formDataToValidate[key];
          if (error) {
            needToReturn = true;
          }
        });

        if (needToReturn) {
          setLoading(false);
          setFormData(formDataToValidate);
          return;
        }

        onFail(`Could not update ${displayName}, please try again`);
        return;
      }
    }

    setCurrentAction({});
    setFormData({});
    setLoading(false);
    if (!nothingToUpdate) {
      await onLoad();
      setShowBanner(true);
      setBannerMessage(`Successfully updated ${displayName}`);
    }
  };

  const deleteUser = async () => {
    const { id, displayName, email, role } = currentAction.user;

    setShowBanner(false);
    setBannerError(false);
    setBannerMessage("");

    setLoading(true);

    try {
      await UserClient.deleteUser(id);
    } catch (e) {
      console.log(e);
      onFail(`Could not delete ${displayName}, please try again`);
      return;
    }

    setCurrentAction({});
    setShowBanner(true);
    setLoading(false);
    const successBanner =
      role === "TRACKING_ONLY"
        ? `${displayName} has been successfully deleted`
        : `${displayName} (${email}) has been successfully deleted`;
    setBannerMessage(successBanner);
    await onLoad();
  };

  const onFail = (errorMessage) => {
    setLoading(false);
    setCurrentAction({});
    setFormData({});
    setShowBanner(true);
    setBannerError(true);
    setBannerMessage(errorMessage);
  };

  const resendInvitation = async (user) => {
    const { id, email, displayName } = user;

    try {
      await UserClient.resendUserInvite(id);
    } catch (e) {
      onFail(`Could not resend email invitation for ${displayName} (${email}), please try again`);
      return;
    }

    user.invitationResent = true;
    setShowBanner(true);
    setBannerMessage(`Email invitation resent to ${displayName} (${email})`);
  };

  const renderResend = (user) => {
    if (!user.invitationResent) {
      return (
        <div className={Style.user_resend_container}>
          <div
            className={Style.user_resend}
            onClick={() => {
              return resendInvitation(user);
            }}
          >
            Resend invitation
          </div>
        </div>
      );
    }
    return <div className={Style.user_resend_countdown}>Invitation resent</div>;
  };

  const renderCell = (value, id, header) => {
    const user = usersRef.current?.[currentPartner.identifier]?.find((user) => {
      return user.id === id;
    });
    const resetPwd = user?.status === RESEND_PASSWORD_STATUS;
    const textStyle = resetPwd ? Style.user_resend_text : Style.user_text;
    return (
      <>
        <div className={textStyle}>{value || "-"}</div>
        {header === "displayName" && user?.status === RESEND_PASSWORD_STATUS && renderResend(user)}
      </>
    );
  };

  if (loading) {
    return <LoadingCircle />;
  }

  const HoverComponent = ({
    display = false,
    onEditClicked,
    onDeleteClicked,
    enableDelete = true,
    enableEdit = true
  }) => {
    return (
      <div className={Style.hover_component}>
        {display && (
          <div>
            {enableEdit && (
              <Edit
                className={Style.edit_button}
                onClick={onEditClicked}
              />
            )}
            {enableDelete && (
              <Delete
                className={Style.delete_button}
                onClick={onDeleteClicked}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const confirmFunctions = {
    create: createUser,
    edit: editUser,
    delete: deleteUser
  };

  return (
    <>
      {currentAction?.action?.length && (
        <UserModal
          data={formData}
          updateDataFn={setFormData}
          currentAction={currentAction}
          currentPartner={currentPartner}
          updateCurrentActionFn={setCurrentAction}
          confirmFns={confirmFunctions}
        />
      )}
      {showBanner && (
        <DisplayBanner
          bannerError={bannerError}
          bannerMessage={bannerMessage}
          onCloseBanner={() => {
            setShowBanner(false);
            setBannerError(false);
            setBannerMessage("");
          }}
        />
      )}
      <ScreenFrame
        title={title}
        color={mainColor}
        secondaryColor={secondaryColor}
        titleIconComponent={
          <UserIcon
            width={29}
            height={29}
            style={{ color: mainColor }}
          />
        }
      >
        <div className={Style.main_container}>
          {hasVendorClients && (
            <div className={Style.left_container}>
              <SearchableTabList
                tabStructure={partnerTabs}
                onSelect={(partner) => {
                  return setCurrentPartner(partner);
                }}
                searchPlaceholder="Search user groups"
                defaultLength={10}
                focusedTab={currentPartner.id}
                tabStyle={Style.tab_style}
                tabListStyle={Style.tab_list_style}
                focusedTabStyle={Style.focused_tab_style}
                tabListHeaderStyle={Style.groupby_label}
              />
            </div>
          )}
          <div className={Style.right_container}>
            <div className={Style.users_list_header}>
              <div className={Style.users_list_title}>{`Manage ${currentPartner.identifier} Users`}</div>
              <div className={Style.users_list_sub_title}>
                {`${usersList[currentPartner.identifier]?.length || 0} ${
                  (usersList[currentPartner.identifier]?.length || 0) === 1 ? "user" : "users"
                }`}
              </div>
            </div>
            {isCreateAuthorized(createButton.roles) && (
              <div
                className={Style.add_button}
                onClick={() => {
                  setCurrentAction({
                    action: "create",
                    isVendorClientUser: currentPartner.identifier !== tenantName
                  });
                }}
              >
                + Add New User
              </div>
            )}
            <div className={Style.list_container}>
              <ListView
                defaultColumnSort={{ key: "displayName", direction: "desc" }}
                headerStructureList={listHeaders}
                headerStyle={{ color: xemelgoStyle.theme.TEXT_SECONDARY, fontSize: 14 }}
                dataList={usersList[currentPartner.identifier] || []}
                emptyListText="No Users"
                enablePagination={false}
                onHoverRenderCondition={() => {
                  return true;
                }}
                onHoverComponent={(hoverRow, user) => {
                  return (
                    <HoverComponent
                      display={hoverRow === user.id}
                      onEditClicked={() => {
                        setCurrentAction({
                          action: "edit",
                          user,
                          isVendorClientUser: currentPartner.identifier !== tenantName
                        });
                      }}
                      enableEdit={isAuthorized(editButton.roles, user.role)}
                      onDeleteClicked={() => {
                        setCurrentAction({
                          action: "delete",
                          user,
                          isVendorClientUser: currentPartner.identifier !== tenantName
                        });
                      }}
                      enableDelete={isAuthorized(deleteButton.roles, user.role)}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </ScreenFrame>
    </>
  );
};

export default withRouter(UsersPageFeature);
