import { useEffect, useMemo, useRef, useState } from "react";
import { PubSub } from "@aws-amplify/pubsub";
import { READ_MODE_OPTIONS, EXCLUDED_ANALYTIC_VALUES_SET, READ_STATUS_OPTIONS } from "./data/constants";
import { getTagsSinceTimestamp } from "../../services/get-recent-tags-service";
import callEdgeOperation, { EDGE_OPERATION_MAP } from "../../utils/call-edge-operation";
import useAuthenticationContext from "../../context/authentication-context";
import useMixpanelContext from "../../context/mixpanel-context";

export const useMountedReader = (detectorVid, edgeApiUrl, readerOptions) => {
  const { sendMixPanelEvent } = useMixpanelContext();
  const { cognitoUser } = useAuthenticationContext();
  const tagMapRef = useRef({});
  const tagsTableIntervalIdRef = useRef({});
  const iotSubscriptionRef = useRef(null);
  const detectorSerialToResetRef = useRef(null);
  const [readStatus, setReadStatus] = useState(READ_STATUS_OPTIONS.NOT_READING);

  const parseDetectorVid = (vid) => {
    const splitVid = vid?.split("#") || [];
    return {
      detectorSerial: splitVid[0] || "",
      antennaId: splitVid[1] || ""
    };
  };

  const { detectorSerial, antennaId } = useMemo(() => {
    return parseDetectorVid(detectorVid);
  }, [detectorVid]);

  const tenantId = useMemo(() => {
    return cognitoUser?.attributes?.["custom:tenantId"];
  }, [cognitoUser]);

  const edgeOperationParams = useMemo(() => {
    return {
      edgeApiUrl,
      deviceId: detectorSerial,
      tenantId,
      sendLogEvent: sendMixPanelEvent
    };
  }, [readerOptions, detectorSerial, tenantId]);

  useEffect(() => {
    return async () => {
      await resetMountedReader();
    };
  }, [detectorSerial, tenantId]);

  const subscribeToMountedReader = () => {
    if (!detectorSerial) {
      return;
    }

    switch (readerOptions.readMode) {
      case READ_MODE_OPTIONS.IOT_TOPIC:
        subscribeToIoTTopic();
        break;
      case READ_MODE_OPTIONS.TAGS_TABLE:
        subscribeToTagsTable();
        break;
      default:
    }
  };

  const subscribeToIoTTopic = () => {
    if (iotSubscriptionRef.current) {
      iotSubscriptionRef.current.unsubscribe();
    }

    const options = {
      clientId: `xemelgo-web-client-${cognitoUser.username}-${Date.now()}`
    };
    tagMapRef.current = {};

    iotSubscriptionRef.current = PubSub.subscribe(`devices/${detectorSerial}/tags`, options).subscribe({
      next: (data) => {
        const { deviceId, tags } = data.value;
        const { antennaId: antennaIdFromTopic } = parseDetectorVid(deviceId);

        tags.forEach((tag) => {
          if (!EXCLUDED_ANALYTIC_VALUES_SET.has(tag.analytic?.value) && antennaIdFromTopic === antennaId) {
            tagMapRef.current[tag.Name] = tag;
          }
        });
      }
    });

    return true;
  };

  const subscribeToTagsTable = async () => {
    const { tagsTableQueryFrequencyInSeconds, tagsTableApiUrl } = readerOptions;
    if (!tagsTableApiUrl) {
      throw new Error("Missing API URL is for reading tags.");
    }

    const lastQueryTimestamp = Date.now() - 1000 * tagsTableQueryFrequencyInSeconds;

    tagMapRef.current = {};

    const intervalId = setInterval(async () => {
      const tags = await getTagsSinceTimestamp(tagsTableApiUrl, detectorSerial, lastQueryTimestamp);
      tags.forEach((tag) => {
        if (!EXCLUDED_ANALYTIC_VALUES_SET.has(tag.analytic?.value)) {
          tagMapRef.current[tag.Name] = tag;
        }
      });
    }, 1000 * tagsTableQueryFrequencyInSeconds);

    clearInterval(tagsTableIntervalIdRef.current);
    tagsTableIntervalIdRef.current = intervalId;
  };

  const getReaderTagMap = (clearTags = false) => {
    if (!detectorVid) {
      return {};
    }

    const tagsMap = tagMapRef.current || {};

    if (clearTags) {
      tagMapRef.current = {};
    }

    return tagsMap;
  };

  const unsubscribeFromMountedReader = () => {
    if (iotSubscriptionRef.current) {
      iotSubscriptionRef.current.unsubscribe();
      iotSubscriptionRef.current = null;
    }

    if (tagsTableIntervalIdRef.current) {
      clearInterval(tagsTableIntervalIdRef.current);
      tagsTableIntervalIdRef.current = null;
    }
  };

  const startMountedReader = async (abortSignal, readSettings) => {
    detectorSerialToResetRef.current = detectorSerial;
    setReadStatus(READ_STATUS_OPTIONS.STARTING);
    subscribeToMountedReader(readSettings);

    const promises = [];

    if (readerOptions.disableIngestion) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          operation: EDGE_OPERATION_MAP.DISABLE_INGESTION_RULE,
          maxRetries: 3,
          abortSignal
        })
      );
    }

    if (readerOptions.startReader) {
      promises.push(callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.START, abortSignal }));
    } else if (readerOptions.restartReader) {
      promises.push(
        callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.STOP, abortSignal }).then(() => {
          return callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.START, abortSignal });
        })
      );
    }

    await Promise.all(promises);
    setReadStatus(READ_STATUS_OPTIONS.IN_PROGRESS);
  };

  const pauseMountedReader = async (abortSignal) => {
    setReadStatus(READ_STATUS_OPTIONS.PAUSING);
    unsubscribeFromMountedReader();

    if (readerOptions.pauseReader) {
      await callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.STOP, abortSignal });
    }

    setReadStatus(READ_STATUS_OPTIONS.PAUSED);
  };

  const resumeMountedReader = async (abortSignal, readSettings) => {
    setReadStatus(READ_STATUS_OPTIONS.STARTING);
    subscribeToMountedReader(readSettings);

    if (readerOptions.pauseReader) {
      await callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.START, abortSignal });
    }

    setReadStatus(READ_STATUS_OPTIONS.IN_PROGRESS);
  };

  const stopMountedReader = async (abortSignal) => {
    await resetMountedReader(abortSignal);
  };

  const resetMountedReader = async (abortSignal) => {
    if (!detectorSerialToResetRef.current) {
      return;
    }
    const detectorSerialToReset = detectorSerialToResetRef.current;
    detectorSerialToResetRef.current = null;

    setReadStatus(READ_STATUS_OPTIONS.STOPPING);
    unsubscribeFromMountedReader();

    const promises = [];

    if (readerOptions.disableIngestion) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          detectorSerial: detectorSerialToReset,
          operation: EDGE_OPERATION_MAP.ENABLE_INGESTION_RULE,
          maxRetries: 3,
          abortSignal
        })
      );
    }

    if (readerOptions.startReaderOnSubmit) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          detectorSerial: detectorSerialToReset,
          operation: EDGE_OPERATION_MAP.START,
          abortSignal
        })
      );
    } else if (readerOptions.stopReaderOnSubmit) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          detectorSerial: detectorSerialToReset,
          operation: EDGE_OPERATION_MAP.STOP,
          abortSignal
        })
      );
    }

    await Promise.all(promises);

    detectorSerialToResetRef.current = null;
    setReadStatus(READ_STATUS_OPTIONS.NOT_READING);
  };

  return {
    startMountedReader,
    pauseMountedReader,
    resumeMountedReader,
    stopMountedReader,
    getReaderTagMap,
    readStatus
  };
};
