import React, { useState, useMemo } from "react";
import { useXemelgoAppsyncClient } from "../../../../../../../../services/xemelgo-appsync-service";
import { useXemelgoClient } from "../../../../../../../../services/xemelgo-service";
import { ACTION_OPTIONS_MAP } from "../../../../../../data/constants";
import getSubmitPayloadByAction from "../../../../../../utils/get-submit-payload-by-action";
import executeAction from "../../../../../../utils/execute-actions";
import useMixpanelContext from "../../../../../../../../context/mixpanel-context";
import { KIOSK, KIOSK_STEPS } from "../../../../../../../../constants/mixpanel-constant/kiosk";
import useKioskStateContext from "../../../../../../contexts/kiosk-state-context";
import useKioskConfigContext from "../../../../../../contexts/kiosk-config-context";
import batchHelper from "../../../../../../../../utils/batch-helper";
import { STATUS_OPTIONS } from "../../../../../../../../components/status-popup-component/data/constants";
import Modal from "../../../../../../../../components/modal";
import useKioskSearchParams from "../../../../../../hooks/use-kiosk-search-params";

const BATCH_SIZE = 500;

const REPORT_ACTIONS_MAP = {
  "check-out": "removed",
  add: "added",
  move: "moved",
  consume: "consumed"
};

export const SubmissionModal = () => {
  const xemelgoClient = useXemelgoClient();
  const xemelgoClientAppSync = useXemelgoAppsyncClient();
  const { sendMixPanelEvent } = useMixpanelContext();
  const [userClient] = useState(xemelgoClientAppSync.getUserClient());
  const [reportClient] = useState(xemelgoClient.getReportClient());
  const [itemClient] = useState(xemelgoClient.getItemClient());
  const { actionId, setActionId } = useKioskSearchParams();

  const { itemByTag, itemTypeReports, panelValues, isSubmitting, setIsSubmitting, setSubmitStatus, setStatusMessage } =
    useKioskStateContext();

  const { reportConfig, actionsMap } = useKioskConfigContext();
  const { additionalSubmitAttributesMap = {}, customSubmitActionsMap = {} } = reportConfig || {};

  const filterForAttributes = (attributes, keysToFilter) => {
    return Object.fromEntries(
      Object.entries(attributes).filter(([key]) => {
        return keysToFilter.includes(key);
      })
    );
  };

  const generateReportContent = async (action, submittedTags, itemsInfoMap) => {
    const { readerLocation = {}, location = {} } = panelValues;
    const { detectorSerial, name: readerLocationName } = readerLocation;
    const { name: locationName = readerLocationName } = location;
    const { itemType: itemTypeSubmitAttributes = [] } = additionalSubmitAttributesMap;

    const formattedPanelValues = Object.keys(panelValues).reduce((accumulator, key) => {
      accumulator[key] = panelValues[key]?.value || panelValues[key];
      return accumulator;
    }, {});

    const itemTypeMap = {};

    for (const itemType of itemTypeReports) {
      const { epcToItemMap, id: itemTypeId, identifier: itemTypeIdentifier, ...itemTypeProps } = itemType;
      const itemTypeAttributesToReport = filterForAttributes(itemTypeProps, itemTypeSubmitAttributes);

      const epcs = Object.keys(epcToItemMap);

      const itemMap = epcs.reduce((itemAccumulator, epc) => {
        const { itemId, identifier, locationName: previousLocation } = itemsInfoMap[epc] || {};
        itemAccumulator[itemId || epc] = {
          id: itemId,
          identifier,
          event_type: "Detection",
          vid: epc,
          previousLocation: action !== ACTION_OPTIONS_MAP.ADD ? previousLocation : null,
          action: submittedTags.includes(epc) ? REPORT_ACTIONS_MAP[action] : "none",
          location: locationName,
          event_time: Date.now()
        };

        return itemAccumulator;
      }, {});

      itemTypeMap[itemTypeId] = {
        ...itemTypeAttributesToReport,
        identifier: itemTypeIdentifier,
        items: itemMap,
        itemCount: epcs.length
      };
    }

    return {
      creation_time: Date.now(),
      class: "Transaction",
      result: {
        ...formattedPanelValues,
        action: REPORT_ACTIONS_MAP[action],
        detector_vid: detectorSerial,
        item_types: itemTypeMap
      }
    };
  };

  const itemCount = useMemo(() => {
    return Object.keys(itemByTag).length;
  }, [itemByTag]);

  const onSubmit = async () => {
    const { location = {}, readerLocation = {} } = panelValues;
    const { detectorId, id: readerLocationId } = readerLocation;
    const { id: locationId = readerLocationId } = location;
    let userId = panelValues?.user?.id;

    setIsSubmitting(false);
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage("Processing...");

    try {
      if (!userId) {
        const userResult = await userClient.queryUser();
        userId = userResult?.queryUser?.id;
      }

      if (Object.values(ACTION_OPTIONS_MAP).includes(actionsMap[actionId].actionType)) {
        const existingItemsInfoMap = (
          await batchHelper(
            Object.keys(itemByTag),
            async (eachBatchPayload) => {
              return itemClient.getItemsBySensorProfileVids(eachBatchPayload);
            },
            BATCH_SIZE
          )
        ).reduce((itemsMap, item) => {
          return { ...itemsMap, [item.vid]: item };
        }, {});

        const scannedItemsInfoByVidMap = Object.keys(itemByTag).reduce((itemsMap, vid) => {
          return { ...itemsMap, [vid]: { ...itemByTag[vid], ...existingItemsInfoMap[vid] } };
        }, {});

        const { payloadsMap, tagsToSubmit } = getSubmitPayloadByAction(
          actionsMap[actionId].actionType,
          panelValues.location,
          scannedItemsInfoByVidMap
        );

        await executeAction(xemelgoClient, payloadsMap, locationId, customSubmitActionsMap);

        const newVidsToQuery = tagsToSubmit.filter((tag) => {
          return !Object.keys(scannedItemsInfoByVidMap).includes(tag);
        });

        const newItemsInfo = await batchHelper(
          newVidsToQuery,
          async (eachBatchPayload) => {
            return itemClient.getItemsBySensorProfileVids(eachBatchPayload);
          },
          BATCH_SIZE
        );

        newItemsInfo.forEach((item) => {
          const { vid } = item;
          scannedItemsInfoByVidMap[vid] = item;
        });
        const fileContent = await generateReportContent(
          actionsMap[actionId].actionType,
          tagsToSubmit,
          scannedItemsInfoByVidMap
        );
        await reportClient.createReport(fileContent, [locationId], userId, detectorId);
      }

      setActionId();
      setSubmitStatus(STATUS_OPTIONS.SUCCESS);
      setStatusMessage(`The items have been ${actionsMap[actionId]?.label?.toLowerCase() || "submitted"} successfully`);
      sendMixPanelEvent(KIOSK, KIOSK_STEPS.SUBMISSION_SUCCESSFUL, { action: actionsMap[actionId].actionType });
    } catch (error) {
      setSubmitStatus(STATUS_OPTIONS.ERROR);
      setStatusMessage(
        `Failed to ${
          actionsMap[actionId]?.label?.toLowerCase() || "submit"
        }. Please contact Xemelgo Support for assistance.`
      );
      sendMixPanelEvent(KIOSK, KIOSK_STEPS.SUBMISSION_FAILED, {
        action: actionsMap[actionId].actionType,
        error: JSON.stringify(error)
      });
    }
  };

  return (
    isSubmitting && (
      <Modal
        title={actionsMap[actionId]?.label || "Submission"}
        onCancel={() => {
          setIsSubmitting(false);
        }}
        onConfirm={onSubmit}
      >
        {`You are about to ${
          actionsMap[actionId]?.label?.toLowerCase() || "submit"
        } ${itemCount} items. Do you want to proceed?`}
      </Modal>
    )
  );
};
