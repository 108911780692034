import React, { Fragment, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import "./style.css";
import { LocationRecord } from "./location-record";
import { ListedItem } from "../../../../components/collapsible-list-view/listed-item";

export const LocationRowsWithOperations = ({
  appId,
  featureId,
  locations,
  onSelectLocation,
  isChildSelected,
  onLocationRecordChanged
}) => {
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [filterInput, setFilterInput] = useState("");
  const onSelectLocationCallback = (location) => {
    const locationId = location.getId();
    setSelectedLocationId(locationId);
    onSelectLocation(location);
  };

  const filteredLocations = useMemo(() => {
    if (filterInput) {
      const lowerCaseFilterInput = filterInput.toLowerCase();
      return locations.filter((location) => {
        return (
          location.getName().toLowerCase().includes(lowerCaseFilterInput) ||
          location.getParent()?.getName().toLowerCase().includes(lowerCaseFilterInput)
        );
      });
    }

    return locations;
  }, [filterInput, locations]);

  useEffect(() => {
    if (!isChildSelected) {
      setSelectedLocationId(null);
    }
  }, [isChildSelected]);

  return (
    <>
      <div className="filter_bar_container">
        <span className="fa fa-search filter_bar_icon icon_offset" />
        <input
          title="Filter Input Field"
          placeholder="Type to Filter"
          onChange={({ currentTarget }) => {
            setFilterInput(currentTarget.value || "");
          }}
          className="filter_bar"
        />
      </div>
      {filteredLocations.map((location) => {
        const locationId = location.getId();

        const styleClassName = `location-row-with-operations ${
          selectedLocationId === locationId ? "location-selected" : ""
        }`;

        return (
          <ListedItem
            className={styleClassName}
            content={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <LocationRecord
                location={location}
                appId={appId}
                featureId={featureId}
                onRecordUpdated={() => {
                  return onLocationRecordChanged(locationId);
                }}
              />
            }
            key={location.getId()}
            onClickCallback={() => {
              return onSelectLocationCallback(location);
            }}
          />
        );
      })}
    </>
  );
};

LocationRowsWithOperations.defaultProps = {
  configuration: {
    showOperations: false
  },
  locations: [],
  onSelectLocation: () => {},
  isChildSelected: false,
  onLocationRecordChanged: () => {}
};

LocationRowsWithOperations.propTypes = {
  configuration: PropTypes.shape({
    showOperations: PropTypes.bool
  }),
  appId: PropTypes.string.isRequired,
  featureId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      getName: PropTypes.func,
      getId: PropTypes.func
    }).isRequired
  ),
  onSelectLocation: PropTypes.func,
  isChildSelected: PropTypes.bool,
  onLocationRecordChanged: PropTypes.func
};
