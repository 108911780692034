import React from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { useInputStyle, userInputLabelStyle, useInputIconStyle } from "./AddPageInputsStyle";

const DatePickerInput = ({
  id,
  label,
  value,
  onChange,
  error,
  required,
  onBlur,
  disabled,
  inputVariant,
  KeyboardButtonProps,
  inputProps,
  InputProps,
  size,
  errorMessage,
  minDate,
  onAccept
}) => {
  const inputStyle = useInputStyle();
  const inputLabelStyle = userInputLabelStyle();
  const inputIconStyle = useInputIconStyle();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        style={disabled ? { borderRadius: 5, backgroundColor: "#F5F5F5" } : {}}
        disabled={disabled}
        KeyboardButtonProps={KeyboardButtonProps || { classes: inputIconStyle }}
        onBlur={onBlur || null}
        inputProps={inputProps}
        InputProps={InputProps || { classes: inputStyle }}
        InputLabelProps={{ classes: inputLabelStyle }}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        inputVariant={inputVariant}
        size={size}
        helperText={errorMessage}
        placeholder="MM/DD/YYYY"
        id={id}
        label={label}
        value={value}
        required={required}
        error={error}
        autoOk
        onChange={(date) => {
          return onChange(id, date);
        }}
        invalidDateMessage={null}
        maxDateMessage={null}
        minDateMessage={null}
        minDate={minDate}
        onAccept={onAccept}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerInput;

DatePickerInput.defaultProps = {
  id: "",
  label: "",
  value: null,
  onChange: () => {},
  error: false,
  required: false,
  onBlur: () => {},
  disabled: false,
  inputVariant: "outlined"
};

DatePickerInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  inputVariant: PropTypes.string,
  KeyboardButtonProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  size: PropTypes.string,
  errorMessage: PropTypes.string,
  minDate: PropTypes.any,
  onAccept: PropTypes.func
};
