import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { isValidPhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import { TextField, IconButton, InputAdornment } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Style from "./DetailsForm.module.css";
import { isValidEmail } from "../../common/Utilities";
import PhoneNumberInput from "../add-page-component/add-page-inputs/PhoneNumberInput";
import CheckBoxGroup from "../add-page-component/add-page-inputs/CheckBoxGroup";
import TextInput from "../add-page-component/add-page-inputs/TextInput";
import {
  useInputStyle,
  usePhoneInputStyle,
  useInputIconStyle
} from "../add-page-component/add-page-inputs/AddPageInputsStyle";

const getInputComponent = (type) => {
  switch (type) {
    case "checkboxGroup":
      return CheckBoxGroup;
    case "phone":
      return PhoneNumberInput;
    case "input":
    case "email":
    default:
      return TextInput;
  }
};

const validateCheckBoxGroup = (value, checkBoxGroup) => {
  let { minimumChecked, maximumChecked, error, errorMessage } = checkBoxGroup;

  let totalCheck = 0;
  Object.keys(value).forEach((key) => {
    if (value[key]) {
      totalCheck += 1;
    }
  });
  if (totalCheck < minimumChecked) {
    error = true;
    errorMessage = `Please choose a minimum of ${minimumChecked} option(s)`;
  } else if (totalCheck > maximumChecked) {
    error = true;
    errorMessage = `Please choose a maximum of ${maximumChecked} option(s)`;
  } else {
    error = false;
    errorMessage = "";
  }
  return { error, errorMessage };
};

const DetailsForm = ({ data, onChangeFn, isVendorClientUser }) => {
  const [formData, setFormData] = useState(data);

  const inputStyle = useInputStyle({ width: "20vw" });
  const phoneInputStyle = usePhoneInputStyle({ width: "17vw" });
  const inputIconStyle = useInputIconStyle();

  const onFormDataChange = (id, value) => {
    if (id) {
      const { type, required } = formData[id];
      const formDataToUpdate = { ...formData, [id]: { ...formData[id], value } };

      if (id === "phone") {
        formDataToUpdate[id].formattedValue = "";
      }
      if (!value && !required) {
        formDataToUpdate[id].error = false;
        formDataToUpdate[id].errorMessage = "";
      }

      if (type === "checkboxGroup") {
        const validationResponse = validateCheckBoxGroup(value, formData[id]);
        formDataToUpdate[id].error = validationResponse.error;
        formDataToUpdate[id].errorMessage = validationResponse.errorMessage;
      }

      setFormData(formDataToUpdate);
      onChangeFn(formDataToUpdate);
    }
  };

  const onBlur = (event, countryCode) => {
    const { id } = event.target;
    const { value, required, type, label } = formData[id];
    const updatedData = _.cloneDeep(formData);
    let error;
    let errorMessage;
    let valueToUpdate = value || "";

    if (typeof value === "string") {
      valueToUpdate = valueToUpdate.trim();
    }

    if (required && !valueToUpdate.length) {
      error = true;
      errorMessage = `${label} is a required field`;
    } else if (type === "email" && valueToUpdate) {
      valueToUpdate = valueToUpdate.toLowerCase();
      const validEmail = isValidEmail(valueToUpdate);
      if (!validEmail) {
        error = true;
        errorMessage = `Please enter a valid email address`;
      }
    } else if (type === "phone" && valueToUpdate) {
      const valueToValidate = `+${getCountryCallingCode(countryCode)}${valueToUpdate}`;
      const validPhoneNumber = isValidPhoneNumber(valueToValidate);
      if (!validPhoneNumber) {
        error = true;
        errorMessage = `Please enter a valid phone number`;
      }
      updatedData[id].formattedValue = valueToValidate;
    } else if (type === "checkboxGroup") {
      const validationResponse = validateCheckBoxGroup(value, formData[id]);
      error = validationResponse.error;
      errorMessage = validationResponse.errorMessage;
    }

    updatedData[id].error = error;
    updatedData[id].errorMessage = errorMessage;
    updatedData[id].value = valueToUpdate;

    setFormData(updatedData);
    onChangeFn(updatedData);
  };

  const renderInput = (key) => {
    const {
      hiddenForVendorClientUser,
      type,
      value,
      formattedValue,
      label,
      required,
      options,
      disabled,
      error,
      errorMessage
    } = formData[key];
    if (isVendorClientUser && hiddenForVendorClientUser) {
      return null;
    }
    const InputByTypeComponent = getInputComponent(type);
    return (
      <div
        className={type === "checkboxGroup" ? Style.check_box_container : Style.input_container}
        key={key}
      >
        {type === "checkboxGroup" && <hr className={Style.divider} />}
        <InputByTypeComponent
          id={key}
          value={value}
          formattedValue={formattedValue}
          label={label}
          required={required}
          minimumChecked={0}
          options={options}
          disabled={disabled}
          onChange={onFormDataChange}
          onBlur={onBlur}
          error={error}
          InputProps={
            type !== "checkboxGroup"
              ? {
                  classes: type === "phone" ? phoneInputStyle : inputStyle,
                  endAdornment: !disabled && (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        classes={inputIconStyle}
                        size="small"
                        onClick={() => {
                          return onFormDataChange(key, "");
                        }}
                        tabIndex={-1}
                      >
                        <ClearIcon
                          fontSize="small"
                          hidden={false}
                        />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              : null
          }
        />
        <p className={Style.input_error_text}>{errorMessage}</p>
      </div>
    );
  };
  return (
    <div className={Style.form_container}>
      <div className={Style.form_content}>
        {Object.keys(formData)
          .sort((a, b) => {
            return formData[a].index - formData[b].index;
          })
          .map((key) => {
            return renderInput(key);
          })}
      </div>
    </div>
  );
};

export default DetailsForm;

DetailsForm.defaultProps = {
  isVendorClientUser: false
};

DetailsForm.propTypes = {
  data: PropTypes.object.isRequired,
  onChangeFn: PropTypes.func.isRequired,
  isVendorClientUser: PropTypes.bool
};
